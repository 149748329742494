import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SET_ORDER_SUMMARY_DETAILS } from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../contexts/cart';
import { ModalContext } from '../../../../aem-core-components/context/ModalContext';
import useCartEstimate from '../../../App/hooks/useCartEstimate';
import { DELIVERY_CHARGE_FLAG } from '../../../../constants/cartConstants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { useCheckDeliveryCharge } from '../../../../hooks/useCheckDeliveryCharge';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import { currencyToLocale, isTier2Radius } from '../../../global/utils/commonUtils';
import { logError } from '../../../global/utils/logger';
import { FACTORYSOURCE } from '../constants/orderSummaryFactoryConstants';
import { useFilterState } from '../../../cap';
import { TOOLTIP_MODAL } from '../../../../aem-core-components/context/Modal/constants';

const useOrderSummaryFactory = props => {
    const orderSummaryIntl = useIntl();
    const { source, formStep = {}, currentStep, feeTypeName } = props || {};
    const userType = useCheckUser();
    const { isSelectedLocationEmpty, isRentalDetailsAvailable } = useCheckLocationEmpty();
    const useModalState = () => useContext(ModalContext);
    const { openModal } = useModalState();
    const [{ cart, howToGetYourOrderDetails, optionalPlan, consumables, isLoading, orderSummaryDetails }, dispatch] =
        useCartState();
    const deliveryChargeFlag = useCheckDeliveryCharge();
    const [{ isEstimatesWithOwnedPc }] = useCartEstimate();
    const [{ viewCart, startDate, endDate }] = useFilterState();
    const isRatesFromTier2Radius = isTier2Radius();
    const { isInStorePickup, showDeliveryEstimate } = viewCart;
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const cartItems = cart?.items;
    const { rentalAmount, deliveryPickUpCharges, fuelCharges, rppCharges, salesTax, allOtherCharges } =
        orderEstimates?.totals || {};
    const { selectedDeliveryTime, selectedPickUpTime } = howToGetYourOrderDetails;
    const { orderSummaryRentals, selectedRentalQtyAndPrice } = consumables;
    const isPerDay = !(startDate && endDate);
    const isInStorePickupFromSessionStorage = JSON.parse(
        localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP) || true
    );
    const isRentalInfoAvailable = isRentalDetailsAvailable();

    useEffect(() => {
        calculateOrderEstimates();
    }, [
        cartItems,
        orderSummaryRentals,
        optionalPlan.isRPPChecked,
        optionalPlan.isFuelChargeChecked,
        isInStorePickupFromSessionStorage,
        currentStep,
        salesTax,
        isRentalInfoAvailable
    ]);

    const isDeliveryFeeRequired = () => {
        try {
            if (isRatesFromTier2Radius) {
                if (!isInStorePickupFromSessionStorage) {
                    // roundtrip
                    if (userType === USER_TYPE.CREDIT) {
                        return true;
                    } else if (userType === USER_TYPE.CASH || userType === USER_TYPE.GUEST) {
                        if (currentStep == formStep?.HTGO) {
                            if ((selectedDeliveryTime?.label || selectedPickUpTime?.label) && !isLoading) {
                                return true;
                            }
                            return false;
                        } else if (currentStep > formStep?.HTGO) {
                            return true;
                        }
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            } else {
                return !isInStorePickupFromSessionStorage;
            }
        } catch (error) {
            logError(error, false, 'isDeliveryFeeRequired');
        }
    };

    const showPerDay = (amount = 0) => {
        try {
            if (isSelectedLocationEmpty()) {
                return '-';
            }
            if (!isNaN(amount) && amount > 0 && isPerDay) {
                return `${currencyToLocale(amount)}/day`;
            }
            return currencyToLocale(amount);
        } catch (error) {
            logError(error, false, 'showPerDay');
        }
    };

    const getEnvFee = () => {
        try {
            const envFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('ENVIRONMENTAL') > -1);
            return startDate && endDate ? Number(envFeeObj?.charge?.toFixed(2)) || 0 : 0;
        } catch (error) {
            logError(error, false, 'getEnvFee');
        }
    };

    const calculatePickupCharge = () => {
        try {
            let value = 0.0;
            if (!isInStorePickup) {
                if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.SHOW_ESTIMATES) {
                    const pickupFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('PICKUP') > -1);
                    const { charge = 0 } = pickupFeeObj || {};
                    value = charge; // it was parseFloat here
                }
            }
            return Number(value.toFixed(2)) || 0;
        } catch (error) {
            logError(error, false, 'calculatePickupCharge');
        }
    };

    const shouldShowTbdAlert = () => {
        try {
            if (source === FACTORYSOURCE.CHECKOUT) {
                if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.TBD) {
                    if (isInStorePickupFromSessionStorage) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
            return false;
        } catch (error) {
            logError(error, false, 'shouldShowTbdAlert');
        }
    };

    const calculateOptionalPlanValue = (isChecked, isEdit, charges) => {
        try {
            return startDate && endDate && isChecked && (isEdit || currentStep > formStep?.OPTIONAL_PLANS)
                ? Number(charges?.toFixed(2)) || 0
                : 0;
        } catch (error) {
            logError(error, false, 'calculateOptionalPlanValue');
        }
    };

    const calculateDeliveryCharge = () => {
        try {
            let value = 0.0;
            if (!isInStorePickup) {
                if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.SHOW_ESTIMATES) {
                    const deliveryFeeObj = orderEstimates?.miscCharges?.find(
                        item => item.type.indexOf('DELIVERY') > -1
                    );
                    const { charge = 0 } = deliveryFeeObj || {};
                    value = charge; // it was parseFloat here
                }
            }
            return Number(value.toFixed(2)) || 0;
        } catch (error) {
            logError(error, false, 'calculateDeliveryCharge');
        }
    };

    const getSubTotalWithDatesEntered = salestax => {
        try {
            if (salestax != 0) {
                const val = !isPerDay ? handleSalesTax(salesTax) + handleEnvAndFuelFees() : 0;

                return val;
            }
            return !isPerDay ? salestax + handleEnvAndFuelFees() : 0;
        } catch (error) {
            logError(error, false, 'getSubTotalWithDatesEntered');
        }
    };

    const handleEnvAndFuelFees = () => {
        try {
            let amountToBeSubtractedFromOtherCharges = Number(allOtherCharges?.toFixed(2));

            const envFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('ENVIRONMENTAL') > -1);
            const { charge = 0 } = envFeeObj || {};

            amountToBeSubtractedFromOtherCharges =
                Number(amountToBeSubtractedFromOtherCharges?.toFixed(2)) - Number(charge?.toFixed(2));

            const fuelFee = fuelCharges ? Number(fuelCharges?.toFixed(2)) : 0;
            amountToBeSubtractedFromOtherCharges =
                Number(amountToBeSubtractedFromOtherCharges?.toFixed(2)) - Number(fuelFee?.toFixed(2));

            const transportFeeToBeRemoved = orderEstimates?.miscCharges?.find(
                item => item.type.indexOf('TRANSPORTATION SURCHARGE') > -1
            );
            amountToBeSubtractedFromOtherCharges =
                Number(amountToBeSubtractedFromOtherCharges?.toFixed(2)) -
                (isInStorePickupFromSessionStorage && transportFeeToBeRemoved?.charge
                    ? Number(transportFeeToBeRemoved?.charge?.toFixed(2))
                    : 0);

            return amountToBeSubtractedFromOtherCharges;
        } catch (error) {
            logError(error, false, 'handleEnvAndFuelFees');
        }
    };

    const getSubTotal = (salestax = 0, purchaseSubtotalValue = 0) => {
        try {
            const envFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('ENVIRONMENTAL') > -1);
            const { charge = 0 } = envFeeObj || {};
            let envFees = !isPerDay ? charge : 0;
            let subtotal =
                (isEstimatesWithOwnedPc() ? cart?.prices?.grand_total?.value : rentalAmount) +
                envFees +
                purchaseSubtotalValue;
            if (optionalPlan?.isRPPChecked && (optionalPlan?.isEdit || currentStep > formStep?.OPTIONAL_PLANS)) {
                subtotal += rppCharges;
            }
            let fuelFee = fuelCharges ? fuelCharges : 0;
            if (optionalPlan?.isFuelChargeChecked && (optionalPlan?.isEdit || currentStep > formStep?.OPTIONAL_PLANS)) {
                subtotal += fuelFee;
            }
            subtotal += getSubTotalWithDatesEntered(salestax);
            if (isDeliveryFeeRequired() && deliveryPickUpCharges) {
                subtotal += deliveryPickUpCharges;
            }
            return Number(subtotal.toFixed(2)) || 0;
        } catch (error) {
            logError(error, false, 'getSubTotal');
        }
    };

    const handleSalesTax = salesTaxVal => {
        try {
            let salesTaxTotal = salesTaxVal;

            const transportFeeTaxToBeRemoved = orderEstimates?.miscCharges?.find(
                item => item.type.indexOf('TRANSPORTATION SURCHARGE') > -1
            );
            salesTaxTotal -= (isInStorePickupFromSessionStorage && transportFeeTaxToBeRemoved?.tax) || 0;

            const fuelFeeTaxToBeRemoved = orderEstimates?.miscCharges?.find(
                item => item.type.indexOf('FUEL CONVENIENCE CHARGE') > -1
            );

            if (
                !(optionalPlan?.isFuelChargeChecked && (optionalPlan?.isEdit || currentStep > formStep?.OPTIONAL_PLANS))
            ) {
                salesTaxTotal -= fuelFeeTaxToBeRemoved?.tax || 0;
            }

            let totalRppTax = 0;
            orderEstimates?.itemizedCharges?.products?.forEach(product => {
                totalRppTax += product?.rppTax;
            });

            if (!(optionalPlan?.isRPPChecked && (optionalPlan?.isEdit || currentStep > formStep?.OPTIONAL_PLANS))) {
                salesTaxTotal -= totalRppTax;
            }

            const deliveryFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('DELIVERY') > -1);
            const pickupFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('PICKUP') > -1);
            if (source === FACTORYSOURCE.CHECKOUT && isInStorePickupFromSessionStorage) {
                salesTaxTotal -= deliveryFeeObj?.tax || 0;
                salesTaxTotal -= pickupFeeObj?.tax || 0;
            } else if (
                source !== FACTORYSOURCE.CHECKOUT &&
                isInStorePickupFromSessionStorage &&
                !showDeliveryEstimate
            ) {
                salesTaxTotal -= deliveryFeeObj?.tax || 0;
                salesTaxTotal -= pickupFeeObj?.tax || 0;
            }
            return Number(salesTaxTotal.toFixed(2)) || 0;
        } catch (error) {
            logError(error, false, 'handleSalesTax');
        }
    };

    const calculateRentalAmountWhenNoDates = () => {
        try {
            let total = 0;
            if (isEstimatesWithOwnedPc()) {
                total = cart?.prices?.grand_total?.value;
            } else {
                if (rentalAmount) {
                    total = rentalAmount;
                } else {
                    if (orderEstimates?.itemizedCharges?.products) {
                        orderEstimates?.itemizedCharges?.products?.forEach(product => {
                            total += product?.rentalCost;
                        });
                    } else {
                        cart?.items?.forEach(item => {
                            total += item?.prices?.row_total?.value;
                        });
                        total += selectedRentalQtyAndPrice?.totalRentalPrice;
                    }
                }
            }
            return Number(total?.toFixed(2)) || 0;
        } catch (error) {
            logError(error, false, 'calculateRentalAmountWhenNoDates');
        }
    };

    const calculateOrderEstimates = () => {
        try {
            if (cartItems) {
                const rentalItemsAmountWithoutDates = calculateRentalAmountWhenNoDates();
                const rentalItemsOrderSubtotal = getSubTotal(0, selectedRentalQtyAndPrice?.totalAddonPrice);
                const rentalItemsEstimatedSubtotal = getSubTotal(salesTax, selectedRentalQtyAndPrice?.totalAddonPrice);
                const envFees = getEnvFee();
                const envAndFuelFees = handleEnvAndFuelFees();
                const otherFees = !isPerDay ? Number(envAndFuelFees?.toFixed(2)) || 0 : 0;
                const taxes = !isPerDay ? handleSalesTax(salesTax) : 0;

                const rentalProtectionPlan = calculateOptionalPlanValue(
                    optionalPlan?.isRPPChecked,
                    optionalPlan?.isEdit,
                    rppCharges
                );
                const prepayFuelOption = calculateOptionalPlanValue(
                    optionalPlan?.isFuelChargeChecked,
                    optionalPlan?.isEdit,
                    fuelCharges
                );
                const deliveryCharges = calculateDeliveryCharge();
                const pickupCharges = calculatePickupCharge();

                let orderSummaryData = {
                    checkoutSubtotal: '-',
                    rentalSubtotal: '-',
                    purchaseSubtotal: '-',
                    deliveryCharges: '-',
                    pickupCharges: '-',
                    deliveryPickUpCharges: '-',
                    rentalProtectionPlan: '-',
                    prepayFuelOption: '-',
                    environmentalServiceFee: '-',
                    otherFees: '-',
                    taxes: '-',
                    allOtherCharges: '-',
                    estimatedSubtotal: '-'
                };
                if (isRentalDetailsAvailable() || source !== FACTORYSOURCE.CART) {
                    orderSummaryData = {
                        checkoutSubtotal: rentalItemsOrderSubtotal,
                        rentalSubtotal: rentalItemsAmountWithoutDates,
                        purchaseSubtotal: Number(selectedRentalQtyAndPrice?.totalAddonPrice?.toFixed(2)) || 0,
                        deliveryCharges: deliveryCharges,
                        pickupCharges: pickupCharges,
                        deliveryPickUpCharges: Number(deliveryPickUpCharges?.toFixed(2)) || 0,
                        rentalProtectionPlan: rentalProtectionPlan,
                        prepayFuelOption: prepayFuelOption,
                        environmentalServiceFee: envFees,
                        otherFees: otherFees,
                        taxes: taxes,
                        allOtherCharges: Number(allOtherCharges?.toFixed(2)) || 0,
                        estimatedSubtotal: rentalItemsEstimatedSubtotal
                    };
                }
                dispatch({
                    type: SET_ORDER_SUMMARY_DETAILS,
                    value: orderSummaryData
                });

                sessionStorage.setItem(
                    STORAGE_CONFIG.SESSION_STORAGE.ORDER_ESTIMATES_FINAL_TOTAL,
                    rentalItemsEstimatedSubtotal
                );
            }
        } catch (error) {
            logError(error, false, 'calculateOrderEstimates');
        }
    };

    const isDelivery = () => {
        const isLocationEmpty = isSelectedLocationEmpty();
        return !isLocationEmpty && (!isRatesFromTier2Radius || isDeliveryFeeRequired());
    };

    const handleIconClick = () => {
        const callDispatch = (title, content) => {
            openModal(TOOLTIP_MODAL, { isOpen: true, title: title, content: content, hideIcon: true });
        };

        switch (feeTypeName) {
            case orderSummaryIntl.formatMessage({ id: 'order-summary:round-trip-delivery' }):
                callDispatch(
                    orderSummaryIntl.formatMessage({ id: 'order-summary:round-trip-delivery' }),
                    orderSummaryIntl.formatMessage({ id: 'order-summary:round-trip-tooltip' })
                );
                break;
            case orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup' }):
                callDispatch(
                    orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup' }),
                    orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup-tooltip' })
                );
                break;
            case orderSummaryIntl.formatMessage({ id: 'order-summary:rental-protection-plan' }):
                callDispatch(
                    orderSummaryIntl.formatMessage({ id: 'order-summary:rental-protection-plan' }),
                    orderSummaryIntl.formatMessage({ id: 'order-summary:rental-protection-plan-tooltip' })
                );
                break;
            case orderSummaryIntl.formatMessage({ id: 'order-summary:fuel-convenience-plan' }):
                callDispatch(
                    orderSummaryIntl.formatMessage({ id: 'order-summary:fuel-convenience-plan' }),
                    orderSummaryIntl.formatMessage({ id: 'order-summary:fuel-convenience-plan-tooltip' })
                );
                break;
            case orderSummaryIntl.formatMessage({ id: 'order-summary:environment-fee' }):
                callDispatch(
                    orderSummaryIntl.formatMessage({ id: 'order-summary:environment-fee' }),
                    orderSummaryIntl.formatMessage({ id: 'order-summary:environment-fee-tooltip' })
                );
                break;
            default:
                break;
        }
    };

    return {
        isDeliveryFeeRequired,
        showPerDay,
        getEnvFee,
        calculatePickupCharge,
        shouldShowTbdAlert,
        calculateOptionalPlanValue,
        calculateDeliveryCharge,
        getSubTotalWithDatesEntered,
        handleEnvAndFuelFees,
        getSubTotal,
        handleSalesTax,
        calculateRentalAmountWhenNoDates,
        calculateOrderEstimates,
        orderSummaryDetails,
        isRatesFromTier2Radius,
        salesTax,
        isDelivery,
        isPerDay,
        showDeliveryEstimate,
        deliveryChargeFlag,
        deliveryPickUpCharges,
        isInStorePickupFromSessionStorage,
        howToGetYourOrderDetails,
        viewCart,
        userType,
        dispatch,
        handleIconClick
    };
};

export default useOrderSummaryFactory;
