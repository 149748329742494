import React from 'react';
import isObjectEmpty from '../../aem-core-components/utils/isObjectEmpty';
import { getRentalDuration } from '../../components/global/utils/commonUtils';
import { VARIABLE_CONFIG } from './Variables';

const getProductName = (productArray, productSku) => {
    const product = productArray.filter(prod => prod?.product?.sku == productSku);
    if (product) return product[0]?.product?.category_path;
    else return '';
};

const populateRentalsAndAddons = (cart, addonsObj, viewCart, productSku = 0, isAddOns) => {
    let addonAssets = [];
    addonAssets.push({
        id: addonsObj?.itemObj['equipmentNumber'] || addonsObj?.itemObj['itemNumber'],
        name: addonsObj?.name,
        price: parseFloat(addonsObj?.price).toFixed(2),
        category:
            getProductName(cart?.items, productSku) ||
            addonsObj?.itemObj?.product?.category_name ||
            VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
        subcategory: isAddOns ? VARIABLE_CONFIG.EVENT_CATEGORY.ADD_ON : VARIABLE_CONFIG.EVENT_CATEGORY.ACCESSOSIERS,
        dimension38: getRentalDuration(viewCart?.startDate, viewCart?.endDate) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
        dimension31: addonsObj?.price,
        dimension32: addonsObj?.price * 7,
        dimension33: addonsObj?.price * 31,
        sku: addonsObj?.itemObj['equipmentNumber'] || addonsObj?.itemObj['itemNumber'],
        dimension41: addonsObj?.itemObj['availQuantity'] || -1,
        position: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
        quantity: addonsObj?.qty,
        forcedItem:addonsObj?.itemObj?.['forceItem'] === 'Y'
    });
    return addonAssets;
};
export const getProducts = (cart, viewCart, isAccessoriesToBeAdded, consumables = {}) => {
    let assets = [];
    if (isAccessoriesToBeAdded) {
        Object.entries(consumables).map((consumable, index) => {
            const isAddOns = consumable?.[0] === VARIABLE_CONFIG.CONSUMABLES_TYPE.ADD_ONS || false;
            Object.entries(consumable[1]).map(rental => {
                let addonsArray = populateRentalsAndAddons(cart, rental[1], viewCart, 0, isAddOns);
                assets.push(addonsArray[0]);
            });
        });
    } else {
        const items = cart?.items;
        items?.forEach(item => {
            assets.push({
                id: item?.product?.sku,
                name: item?.product?.name,
                price: parseFloat(item?.prices?.row_total?.value).toFixed(2),
                categories: item?.product?.category_path || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                category: item?.product?.productcategoryname || item?.product?.parent_category_name || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                subcategory: item?.product?.category_name || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                dimension38: getRentalDuration(viewCart?.startDate, viewCart?.endDate),
                dimension31: item?.prices?.row_total?.value,
                dimension32: item?.prices?.row_total?.value * 7,
                dimension33: item?.prices?.row_total?.value * 31,
                sku: item?.product?.sku,
                dimension41: -1,
                position: parseInt(item?.product?.position) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                quantity: item?.quantity
            });
        });
        if (!isObjectEmpty(consumables?.selected)) {
            Object.values(consumables?.selected).map((consumable, index) => {
                Object.values(consumable?.rentals).map(rental => {
                    assets.push(
                        populateRentalsAndAddons(
                            cart, 
                            rental, 
                            viewCart, 
                            Object.keys(consumables?.selected ?? {})[index], 
                            false
                          )?.[0]
                    );
                });
                Object.values(consumable?.addons).map(addon => {
                    assets.push(
                        populateRentalsAndAddons(
                            cart, 
                            addon, 
                            viewCart, 
                            Object.keys(consumables?.selected ?? {})[index], 
                            true
                          )?.[0]
                          
                    );
                });
            });
        }
    }

    return assets;
};
