import React, { useState } from 'react';
import { func, number, oneOfType, string } from 'prop-types';
import './contentCard.scss';
import DefaultViewNoImage from '../../atoms/defaultViewNoImage/defaultViewNoImage';

const ContentCard = props => {
    const {
        cardSideContent,
        cardBottomContent,
        imageSource,
        customClass,
        customImgClass,
        contentCardCustomClass,
        imageTestId,
        onCardClick,
        tabIndex,
        role,
        ariaDescribedby
    } = props;
    const [brokenImage, setBrokenImage] = useState(false);

    return (
        <div className={customClass || 'contentCard'}>
            <div
                role={role}
                tabIndex={tabIndex}
                className={`contentCard-side ${contentCardCustomClass && contentCardCustomClass}`}
                onClick={onCardClick}
                onKeyDown={(event) => event.key === "Enter" ? onCardClick() : null}
                aria-describedby={ariaDescribedby}
            >
                {imageSource && !brokenImage ? (
                    <img
                        src={imageSource}
                        alt="Accessory"
                        className={`contentCard-side-leftcontent ${customImgClass && customImgClass}`}
                        data-testid={imageTestId}
                        onError={() => {
                            setBrokenImage(true);
                        }}
                    />
                ) : (
                    <DefaultViewNoImage cardType="consumables"></DefaultViewNoImage>
                )}
                {cardSideContent()}
            </div>
            {cardBottomContent && cardBottomContent()}
        </div>
    );
};

ContentCard.defaultProps = {
    cardBottomContent: () => { },
    cardSideContent: () => { },
    onCardClick: () => { },
    customClass: '',
    customImgClass: '',
    imageSource: '',
    contentCardCustomClass: '',
    role: '',
    tabIndex: 0,
    ariaDescribedby: ''
};

ContentCard.propTypes = {
    cardSideContent: func,
    cardBottomContent: func,
    onCardClick: func,
    imageSource: string,
    customClass: string,
    customImgClass: string,
    contentCardCustomClass: string,
    role: string,
    tabIndex: oneOfType([number, string]),
    ariaDescribedby: string
};

export default ContentCard;
