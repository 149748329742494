import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import Button from '../button';
import Alert from '../alert/alert';
import './buttonWithTnCCheckbox.scss';

export default function ButtonWithTnCCheckbox(props) {
    const {
        customClass,
        isCheckboxVisible,
        isChecked,
        onCheckboxClick,
        checkboxLabel,
        customAriaLabel,
        errorText,
        buttonTitle,
        handleButtonClick,
        checkboxDataTestId,
        disableClick
    } = props;
    return (
        <div className={`submit-reservation-CTA-container ${customClass} ${disableClick}`}>
            {isCheckboxVisible && (
                <Checkbox
                    customClassName={`tnC_wrapper ${errorText && 'checkboxError'}`}
                    isChecked={isChecked}
                    onClick={onCheckboxClick}
                    label={checkboxLabel()}
                    dataTestId={checkboxDataTestId}
                    customAriaLabel={customAriaLabel}
                />
            )}
            {errorText && (
                <div className="checkbox__errorContainer">
                    <Alert
                        className="alert alert-withmargin"
                        type={'error'}
                        message={errorText}
                    />

                </div>
            )}
            <Button
                className={`button button-primary button-block`}
                type="button"
                buttonAriaLabel={buttonTitle}
                onClick={handleButtonClick}
            // data-testid={continueButtonDataLocator}
            >
                {buttonTitle}
            </Button>
        </div>
    );
}
