import React from 'react';
import CheckoutPayment from './checkoutPayment';
import HowToGetOrder from './howToGetYourOrder/HowToGetOrder';
import AccessoriesAndAddons from './accessoriesAndAddons';
import SubmitReservationButton from '../global/atoms/SubmitReservation';
import useMedia from '../../hooks/useMedia';
import OptionalPlans from './optionalPlans/OptionalPlans';

const CheckoutCashUser = ({ currentStep, setCurrentStep, currentOffSet, formStep, isAccessoriesAddonsAvailable, isShimmerNotVisible }) => {
    const mediaType = useMedia();
    const isSubmitReservationEnabled = currentStep === formStep.SUBMIT_RESERVATION ? true : false;
    
    return (
        <>
            <HowToGetOrder
                currentStep={currentStep}
                handleStepChange={setCurrentStep}
                currentOffSet={currentOffSet}
                formStep={formStep}
            />
            {isAccessoriesAddonsAvailable() && currentStep > formStep.HTGO && (
                <AccessoriesAndAddons
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                    currentOffSet={currentOffSet}
                    formStep={formStep}
                    isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                />
            )}
            <OptionalPlans currentStep={currentStep} handleStepChange={setCurrentStep} formStep={formStep} />
            <CheckoutPayment currentStep={currentStep} handleStepChange={setCurrentStep} formStep={formStep} isShimmerNotVisible={isShimmerNotVisible} />
            {isSubmitReservationEnabled && mediaType === 'DESKTOP' && (
                <SubmitReservationButton
                    customClass={'submitCTAdesktopView'}
                    currentOffSet={currentOffSet}
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                />
            )}
        </>
    );
};

export default CheckoutCashUser;
