import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useCartState } from '../../../contexts/cart';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import useCheckout from '../../../hooks/useCheckout';
import { getReviewDuplicatesAction } from '../../../aem-core-components/actions/user';
import { useFilterState } from '../../cap';
import { EditableView } from '../../global/modules/stepForm';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import './editCreateJobsite.scss';
import Button from '../../global/atoms/button';
import EditButton from '../../global/atoms/editButton/EditButton';
import Input from '../../global/atoms/input';
import { LoadingIndicator } from '../../../aem-core-components';
import {
    validateAccessNotes,
    validateJobSite,
    validatePONumber,
    validatePhone,
    validatePrimaryContact
} from '../../global/utils/commonUtils';
import isObjectWithKeys from '../../../aem-core-components/utils/isObjectWithKeys';
import mutation_review_duplicates from '../../../aem-core-components/queries/mutation_review_duplicate_projects.graphql';
import { createFullAddress } from '../../cap/utils/capHelper';
import PhoneNumber from '../../global/modules/formFields/phoneNumber';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons';
import HelplineNumber from '../../global/atoms/helplineNumber/HelplineNumber';
import Alert from '../../global/atoms/alert/alert';
import ReviewDuplicates from './reviewDuplicates/ReviewDuplicates';
import AlertTriangleRed from '../../../resources/images/alert-triangle-red.svg';
import {
    ADDERESS_NOT_VALIDATED,
    INVALID_PHONE_NUMBER,
    PROJECTSITE_ALREADY_EXISTS,
    SYSTEMIC_ERROR_CODES,
    SYSTEMIC_AXIOS_ERROR_CODES,
    JOBSITE_NAME_ID,
    JOBSITE_PRIMARY_CONTACT_ID,
    JOBSITE_PHONE_NUMBER_ID,
    JOBSITE_ACCESS_NOTES_ID,
    JOBSITE_PO_NUMBER_ID,
    SCROLL_MARGIN_TOP_VALUE
} from '../constants';
import TextArea from '../../global/atoms/textArea';
import {
    EDIT_PROJECT_INFO,
    RESET_LOCATION_DATA,
    SET_CREDIT_NEWADDR_FLAG,
    UPDATE_PROJECT
} from '../../../aem-core-components/actions/constants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { logError } from '../../global/utils/logger';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';

const EditCreateJobsite = props => {
    const intl = useIntl();
    const headingRef = useRef(null);
    const [{ userAccount, isCreditNewAddress, isLoading }, dispatch] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch, { setSettingsCookieFunc }] = useFilterState();
    const [userState, { getAccountProjectsDotCom }] = useUserContext();
    const { handleEditInCart, addProject, editProject, checkoutErrorAnalyticsEvent } = useCheckout();
    const [reviewDuplicatesGQL] = useMutation(mutation_review_duplicates);
    const { accountProjects } = userState;
    const { currentStep, handleStepChange, formStep, multipleErrors, setMultipleErrors } = props;
    const [jobsiteName, setJobsiteName] = useState(projectDetails?.projectName || '');
    const [isValidProjectName, setIsValidProjectName] = useState(false);
    const [primaryContact, setPrimaryContact] = useState(projectDetails?.primaryContactName || '');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(projectDetails?.phoneNumber || '');
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [accessNotes, setAccessNotes] = useState(projectDetails?.accessNotes || '');
    const [poNumber, setPONumber] = useState(projectDetails?.poNumber || '');
    const [formErrors, setFormErrors] = useState({
        [JOBSITE_NAME_ID]: '',
        [JOBSITE_PRIMARY_CONTACT_ID]: '',
        [JOBSITE_PHONE_NUMBER_ID]: '',
        [JOBSITE_ACCESS_NOTES_ID]: '',
        [JOBSITE_PO_NUMBER_ID]: ''
    });
    const [isContinueClicked, setIsContinueClicked] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [newProject, setNewProject] = useState({});
    const [duplicates, setDuplicates] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [showReviewDuplicates, setShowReviewDuplicates] = useState(false);
    const [isNewProject, setIsNewProject] = useState(true);
    const [error, setError] = useState('');
    const [validAccessNotes, setValidAccessNotes] = useState(true);
    const [validPONumber, setValidPONumber] = useState(true);
    const [validPrimaryContact, setValidPrimaryContact] = useState(false);
    const [systemicError, setSystemicError] = useState('');

    const FIELDS = [
        {
            label: `${intl.formatMessage({ id: 'create-jobsite:jobsite-name-label' })}*`,
            id: JOBSITE_NAME_ID
        },
        {
            label: `${intl.formatMessage({ id: 'create-jobsite:primary-contact-label' })}*`,
            id: JOBSITE_PRIMARY_CONTACT_ID
        },
        {
            label: `${intl.formatMessage({ id: 'create-jobsite:mobile-number' })}*`,
            id: JOBSITE_PHONE_NUMBER_ID
        },
        {
            label: intl.formatMessage({ id: 'create-jobsite:access-notes-label' }),
            id: JOBSITE_ACCESS_NOTES_ID
        },
        {
            label: intl.formatMessage({ id: 'create-jobsite:po-number-label' }),
            id: JOBSITE_PO_NUMBER_ID
        }
    ];

    useEffect(() => {
        if (userAccount?.accountNumber) {
            if (accountProjects?.length === 0) {
                getAccountProjectsDotCom(userAccount?.accountNumber);
            }
        }
    }, [userAccount?.accountNumber]);

    useEffect(() => {
        if (isObjectWithKeys(formErrors)) {
            const multipleErr = FIELDS?.filter(item => formErrors[item?.id]);
            setMultipleErrors(multipleErr);
        }
    }, [formErrors]);

    useEffect(() => {
        if (multipleErrors?.length > 0 && isContinueClicked) {
            const firstErrorField = document.getElementById(multipleErrors[0]?.id);
            if (firstErrorField) {
                firstErrorField.parentElement.style.scrollMarginTop = `${SCROLL_MARGIN_TOP_VALUE}px`;
                firstErrorField.parentElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
            setIsContinueClicked(false);
        }
    }, [multipleErrors, isContinueClicked]);

    const updateFormErrors = (fieldId, errorMessage) => {
        checkoutErrorHandlingAnalytics(errorMessage, fieldId);
        setFormErrors(prevErrors => ({
            ...prevErrors,
            [fieldId]: errorMessage
        }));
    };

    const onChangeProjectName = text => {
        setJobsiteName(text.target.value);
        if (text.target.value) {
            updateFormErrors(JOBSITE_NAME_ID, '');
            setIsValidProjectName(true);
        }
        setSubmit(false);
    };

    const onChangePrimaryContact = text => {
        if (validatePrimaryContact(text.target.value)) {
            setPrimaryContact(text.target.value);
            updateFormErrors(JOBSITE_PRIMARY_CONTACT_ID, '');
        }
        setSubmit(false);
    };

    const onChangeAccessNotes = text => {
        if (validateAccessNotes(text.target.value)) {
            setAccessNotes(text.target.value);
            updateFormErrors(JOBSITE_ACCESS_NOTES_ID, '');
        }
    };

    const onChangePONumber = text => {
        if (validatePONumber(text.target.value)) {
            setPONumber(text.target.value);
            updateFormErrors(JOBSITE_PO_NUMBER_ID, '');
        }
    };

    const handlePhoneNumberChange = text => {
        setSelectedPhoneNumber(text.target.value);
        updateFormErrors(JOBSITE_PHONE_NUMBER_ID, '');
        setSubmit(false);
    };

    const validateJobsiteInfo = () => {
        let isValid = true;
        if (!jobsiteName) {
            updateFormErrors(JOBSITE_NAME_ID, intl.formatMessage({ id: 'checkout:required-jobsite-error' }));
            isValid = false;
            setIsValidProjectName(false);
        } else {
            if (!validateJobSite(jobsiteName)) {
                isValid = false;
                setIsValidProjectName(false);
                if (jobsiteName?.length > 30) {
                    updateFormErrors(
                        JOBSITE_NAME_ID,
                        intl.formatMessage({ id: 'checkout:required-jobsite-characters-error' })
                    );
                } else {
                    updateFormErrors(
                        JOBSITE_NAME_ID,
                        intl.formatMessage({ id: 'create-jobsite:special-characters-not-allowed' })
                    );
                }
            } else {
                updateFormErrors(JOBSITE_NAME_ID, '');
                setIsValidProjectName(true);
            }
        }
        if (!primaryContact) {
            updateFormErrors(JOBSITE_PRIMARY_CONTACT_ID, intl.formatMessage({ id: 'common:required-input-error' }));
            isValid = false;
            setValidPrimaryContact(false);
        } else {
            if (!validatePrimaryContact(primaryContact)) {
                isValid = false;
                setValidPrimaryContact(false);
                updateFormErrors(JOBSITE_PRIMARY_CONTACT_ID, intl.formatMessage({ id: 'common:input-format-error' }));
            } else {
                setValidPrimaryContact(true);
                updateFormErrors(JOBSITE_PRIMARY_CONTACT_ID, '');
            }
        }
        if (!selectedPhoneNumber) {
            updateFormErrors(JOBSITE_PHONE_NUMBER_ID, intl.formatMessage({ id: 'common:required-input-error' }));
            isValid = false;
        } else {
            if (!validatePhone(selectedPhoneNumber)) {
                isValid = false;
                setValidPhoneNumber(false);
                updateFormErrors(JOBSITE_PHONE_NUMBER_ID, intl.formatMessage({ id: 'common:format-phone-error' }));
            } else {
                setValidPhoneNumber(true);
                updateFormErrors(JOBSITE_PHONE_NUMBER_ID, '');
            }
        }

        if (accessNotes) {
            if (!validateAccessNotes(accessNotes)) {
                isValid = false;
                setValidAccessNotes(false);
                updateFormErrors(JOBSITE_ACCESS_NOTES_ID, intl.formatMessage({ id: 'common:input-format-error' }));
            } else {
                setValidAccessNotes(true);
                updateFormErrors(JOBSITE_ACCESS_NOTES_ID, '');
            }
        }

        if (poNumber) {
            if (!validatePONumber(poNumber)) {
                isValid = false;
                setValidPONumber(false);
                updateFormErrors(JOBSITE_PO_NUMBER_ID, intl.formatMessage({ id: 'common:input-format-error' }));
            } else {
                setValidPONumber(true);
                updateFormErrors(JOBSITE_PO_NUMBER_ID, '');
            }
        }
        return isValid;
    };

    const showReviewScreen = duplicateProjects => {
        const newProject = {
            jobNumber: 'new',
            jobName: jobsiteName,
            address1: isCreditNewAddress ? viewCart?.location?.split(',')[0] : projectDetails?.projectAddress1,
            address2: isCreditNewAddress ? viewCart?.jobSiteAddr2 : projectDetails?.projectAddress2,
            city: isCreditNewAddress ? viewCart?.jobSiteCity : projectDetails?.selectedProjectCity,
            state: isCreditNewAddress ? viewCart?.jobSiteState : projectDetails?.selectedProjectState,
            zip: isCreditNewAddress ? viewCart?.jobSiteZip : projectDetails?.selectedProjectZip
        };
        setNewProject(newProject);
        setDuplicates([...duplicateProjects]);
        setSelectedProject(newProject);
        toggleDuplicateView();
    };

    const onPressProject = project => {
        setSelectedProject(project);
        if (project?.jobNumber === 'new') {
            setIsNewProject(true);
        } else {
            setIsNewProject(false);
        }
    };

    const toggleDuplicateView = () => {
        setShowReviewDuplicates(!showReviewDuplicates);
    };

    const fetchDuplicatesProjects = async () => {
        try {
            let reviewDuplicatesData = [];
            if (isCreditNewAddress) {
                dispatch({ type: 'beginLoading' });
                reviewDuplicatesData = await getReviewDuplicatesAction({
                    reviewDuplicatesGQL,
                    payloadForReviewDuplicates: { search: viewCart?.location },
                    accountNumber: userAccount?.accountNumber
                });
                dispatch({ type: 'endLoading' });
            }
            if (reviewDuplicatesData?.length === 0) {
                createProject();
            } else {
                showReviewScreen(reviewDuplicatesData);
            }
        } catch (error) {
            logError(error, false, 'fetchDuplicatesProjects ');
        }
    };

    useEffect(() => {
        const fn = async () => {
            if (
                submit &&
                isValidProjectName &&
                validPhoneNumber &&
                validAccessNotes &&
                validPONumber &&
                validPrimaryContact
            ) {
                setSubmit(false);
                await fetchDuplicatesProjects();
            }
        };
        fn();
    }, [submit, isValidProjectName, validPhoneNumber, validAccessNotes, validPONumber, validPrimaryContact]);

    const submitForm = () => {
        setSubmit(true);
        validateJobsiteInfo();
        setIsContinueClicked(true);
        setError('');
    };

    const toggleSystemicErrorModal = () => {
        setSystemicError(!systemicError);
    };
    const checkoutErrorHandlingAnalytics = (error, field) => {
        if (error) {
            checkoutErrorAnalyticsEvent(formStep, currentStep, error, field);
        }
    };
    const updateNonFormErrors = (error, field = '') => {
        setError(error);
        checkoutErrorHandlingAnalytics(error, field);
    };

    const renderSystemicErrorContent = () => {
        return (
            <HelplineNumber
                message={intl.formatMessage({ id: 'checkout:systemic-error-content' })}
                phoneNumber={ENV_CONFIG.PHONE_NUMBER}
                customClass="systemic-error-msg"
            />
        );
    };

    const createNewProject = () => {
        if (isNewProject === true) {
            createProject();
        } else {
            filterDispatch({
                type: UPDATE_PROJECT,
                locationPC: selectedProject?.address[0]?.locationPC,
                projectName: selectedProject?.projectName,
                projectAddress1: selectedProject?.address[0]?.line1,
                selectedProjectJobId: selectedProject?.projectId,
                selectedRMJobId: selectedProject?.rmJobsiteId,
                selectedProjectLatititude: selectedProject?.address[0]?.latitude,
                selectedProjectLongitude: selectedProject?.address[0]?.longitude,
                selectedProjectState: selectedProject?.address[0]?.state,
                selectedProjectCity: selectedProject?.address[0]?.city,
                selectedProjectZip: selectedProject?.address[0]?.zip,
                primaryContactName: selectedProject?.contact?.name,
                phoneNumber: selectedProject?.contact?.phone,
                accessNotes: selectedProject?.deliveryInstructions1,
                poNumber: selectedProject?.customerPO,
                CJDLVY: selectedProject?.CJDLVY
            });
            dispatch({
                type: EDIT_PROJECT_INFO,
                primaryContactName: selectedProject?.contact?.name,
                phoneNumber: selectedProject?.contact?.phone,
                accessNotes: selectedProject?.deliveryInstructions1?.trim(),
                poNumber: selectedProject?.customerPO
            });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, selectedProject?.CJDLVY);
            toggleDuplicateView();
            handleStepChange(formStep.HTGO);
        }
    };
    const createAddOrEditProjectPayload = () => {
        let delInstParts = accessNotes?.match(/.{1,40}/g) || [];
        let delInst1 = delInstParts[0];
        let delInst2 = delInstParts[1];
        let delInst3 = delInstParts[2];
        let delInst4 = delInstParts[3];

        let addProjectPayload = {
            accountId: String(userAccount?.accountNumber),
            projectLocation: isCreditNewAddress ? viewCart?.location : createFullAddress(projectDetails),
            projectName: jobsiteName?.trim(),
            projectNumber: jobsiteName?.trim(),
            status: 'C',
            updateOpenHeader: 'N',
            deliveryInstructions1: delInst1 ? delInst1 : '',
            deliveryInstructions2: delInst2 ? delInst2 : '',
            deliveryInstructions3: delInst3 ? delInst3 : '',
            deliveryInstructions4: delInst4 ? delInst4 : '',
            poNumber: poNumber,
            address: {
                line1: isCreditNewAddress ? viewCart?.location?.split(',')?.[0] : projectDetails?.projectAddress1,
                line2: isCreditNewAddress ? viewCart?.jobSiteAddr2 : projectDetails?.projectAddress2,
                city: isCreditNewAddress ? viewCart?.jobSiteCity : projectDetails?.selectedProjectCity,
                state: isCreditNewAddress ? viewCart?.jobSiteState : projectDetails?.selectedProjectState,
                zip: isCreditNewAddress ? viewCart?.jobSiteZip : projectDetails?.selectedProjectZip,
                location: String(viewCart?.pc)
            },
            contact: {
                name: primaryContact,
                phoneNumber: selectedPhoneNumber
            }
        };

        let editProjectPayload = {
            accountId: String(userAccount?.accountNumber),
            projectName: jobsiteName?.trim(),
            projectNumber: projectDetails?.selectedProjectJobId,
            isActive: 'A',
            updateOpenHeader: 'N',
            deliveryInstructions1: delInst1 ? delInst1 : '',
            deliveryInstructions2: delInst2 ? delInst2 : '',
            deliveryInstructions3: delInst3 ? delInst3 : '',
            deliveryInstructions4: delInst4 ? delInst4 : '',
            poNumber: poNumber,
            contact: {
                name: primaryContact,
                phoneNumber: selectedPhoneNumber
            }
        };

        return isCreditNewAddress ? addProjectPayload : editProjectPayload;
    };

    const checkIfFieldChange = (oldValue, newValue) => {
        return newValue?.toLowerCase() !== oldValue?.toLowerCase();
    };

    const hasEditJobsiteFieldChanged = () => {
        const hasFieldChanged =
            checkIfFieldChange(projectDetails?.projectName, jobsiteName?.trim()) ||
            checkIfFieldChange(projectDetails?.primaryContactName, primaryContact) ||
            checkIfFieldChange(projectDetails?.phoneNumber, selectedPhoneNumber) ||
            checkIfFieldChange(projectDetails?.accessNotes, accessNotes) ||
            checkIfFieldChange(projectDetails?.poNumber, poNumber);

        return hasFieldChanged;
    };

    const createProject = async () => {
        try {
            dispatch({ type: 'beginLoading' });
            if (!isCreditNewAddress && !hasEditJobsiteFieldChanged()) {
                handleStepChange(formStep.HTGO);
            } else {
                let payload = createAddOrEditProjectPayload();
                let response = {};
                if (isCreditNewAddress) {
                    response = await addProject(payload, localStorage.getItem('companyID') || 1);
                } else {
                    response = await editProject(payload);
                }
                const { projectData, error, addressInfo } = response || {};
                if (error) {
                    const statusCode = error?.errorCode?.slice(-1) || '';
                    if (
                        SYSTEMIC_ERROR_CODES?.includes(statusCode) ||
                        SYSTEMIC_AXIOS_ERROR_CODES?.includes(error?.code)
                    ) {
                        checkoutErrorHandlingAnalytics(
                            intl.formatMessage({ id: 'checkout:systemic-error-title' }),
                            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CREATE_JOBSITE_ERROR
                        );
                        setSystemicError(true);
                        dispatch({ type: 'endLoading' });
                        return;
                    }
                    setShowReviewDuplicates(false);
                    switch (error?.errorKey) {
                        case ADDERESS_NOT_VALIDATED:
                            updateNonFormErrors(
                                `${intl.formatMessage({ id: 'checkout:create-project-invalid-address' })} ${
                                    addressInfo?.addrMsg
                                }`,
                                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ADDRESS_FIELD
                            );
                            break;
                        case PROJECTSITE_ALREADY_EXISTS:
                            updateNonFormErrors(
                                intl.formatMessage({ id: 'checkout:create-project-duplicate-project-name' }),
                                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_NAME_JOBSITE_NAME
                            );
                            break;
                        case INVALID_PHONE_NUMBER:
                            updateFormErrors(
                                JOBSITE_PHONE_NUMBER_ID,
                                intl.formatMessage({ id: 'checkout:invalid-phone-number-error' })
                            );
                            break;
                        default:
                            updateNonFormErrors(`${error?.errorKey?.replaceAll('_', ' ') || error}`);
                            break;
                    }
                } else {
                    setError('');
                    localStorage.setItem('isCreditNewAddress', false);
                    filterDispatch({
                        type: UPDATE_PROJECT,
                        locationPC: projectData?.locationPC,
                        projectName: projectData?.jobName,
                        projectAddress1: projectData?.address1,
                        projectAddress2: projectData?.address2,
                        selectedProjectJobId: projectData?.jobNumber,
                        selectedRMJobId: projectData?.rmJobsiteId,
                        selectedProjectLatititude: projectData?.latitude,
                        selectedProjectLongitude: projectData?.longitude,
                        selectedProjectState: projectData?.state,
                        selectedProjectCity: projectData?.city,
                        selectedProjectZip: projectData?.zip,
                        primaryContactName: projectData?.contactName,
                        phoneNumber: projectData?.contactPhone,
                        accessNotes: projectData?.deliveryInstructions,
                        poNumber: projectData?.customerPO,
                        CJDLVY: projectData?.CJDLVY
                    });
                    dispatch({
                        type: EDIT_PROJECT_INFO,
                        primaryContactName: projectData?.contactName,
                        phoneNumber: projectData?.contactPhone,
                        accessNotes: projectData?.deliveryInstructions?.trim(),
                        poNumber: projectData?.customerPO
                    });
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, projectData?.CJDLVY);
                    dispatch({
                        type: SET_CREDIT_NEWADDR_FLAG,
                        isCreditNewAddress: false
                    });
                    filterDispatch({
                        type: RESET_LOCATION_DATA
                    });
                    if (showReviewDuplicates) {
                        toggleDuplicateView();
                    }
                    setSettingsCookieFunc(null, {
                        locationPC: projectData?.locationPC,
                        projectName: projectData?.jobName,
                        projectAddress1: projectData?.address1,
                        projectAddress2: projectData?.address2,
                        selectedProjectJobId: projectData?.jobNumber,
                        selectedProjectLatititude: projectData?.latitude,
                        selectedProjectLongitude: projectData?.longitude,
                        selectedProjectState: projectData?.state,
                        selectedProjectCity: projectData?.city,
                        selectedProjectZip: projectData?.zip,
                        primaryContactName: projectData?.contactName,
                        phoneNumber: projectData?.contactPhone,
                        accessNotes: projectData?.deliveryInstructions,
                        poNumber: projectData?.customerPO
                    });
                    handleStepChange(formStep.HTGO);
                }
            }
            dispatch({ type: 'endLoading' });
        } catch (error) {
            logError(error, false, 'createProject ');
            dispatch({ type: 'endLoading' });
        }
    };

    const editInCart = () => {
        handleEditInCart(intl.formatMessage({ id: 'checkout:edit-in-cart' }), VARIABLE_CONFIG.CHECKOUT_PAGE_LOCATION.CREATE_JOBSITE);
    }

    const renderEditableView = () => {
        return (
            <>
                {isLoading && !showReviewDuplicates && (
                    <div className={'orderSummaryLoader'}>
                        <LoadingIndicator />
                    </div>
                )}
                <div className="address-container">
                    <label>{intl.formatMessage({ id: 'checkout:create-jobsite-address-text' })}</label>
                    {isCreditNewAddress ? (
                        <address>
                            {viewCart?.location?.split(',')[0]} <br />
                            {viewCart?.jobSiteCity}, {viewCart?.jobSiteState} {viewCart?.jobSiteZip}
                        </address>
                    ) : (
                        <address>
                            {projectDetails?.projectAddress1} <br />
                            {projectDetails?.selectedProjectCity}, {projectDetails?.selectedProjectState}
                            {projectDetails?.selectedProjectZip}
                        </address>
                    )}
                    <EditButton
                        testId={checkoutDatalocator.checkout_edit_cart_CTA_testid}
                        label={intl.formatMessage({ id: 'checkout:edit-in-cart' })}
                        handleOnClick={editInCart}
                    />
                </div>

                <Input
                    name={JOBSITE_NAME_ID}
                    label={intl.formatMessage({ id: 'create-jobsite:jobsite-name-label' })}
                    value={jobsiteName}
                    handleInputChange={onChangeProjectName}
                    dataTestId={checkoutDatalocator.jobsiteNamePlaceHolder}
                    errorMsg={formErrors[JOBSITE_NAME_ID]}
                    showErrorIcon
                    inputAriaLabel={intl.formatMessage({ id: 'create-jobsite:jobsite-name-placeholder' })}
                    required={true}
                    inputAriaRequired={true}
                    disclaimerMsg={intl.formatMessage({ id: 'create-jobsite:jobsite-message' })}
                />
                <Input
                    name={JOBSITE_PRIMARY_CONTACT_ID}
                    label={intl.formatMessage({ id: 'create-jobsite:primary-contact-label' })}
                    value={primaryContact}
                    maxLength="30"
                    handleInputChange={onChangePrimaryContact}
                    lbltestid={checkoutDatalocator.primaryContactHelperText}
                    dataTestId={checkoutDatalocator.primaryContactPlaceHolder}
                    errorMsg={formErrors[JOBSITE_PRIMARY_CONTACT_ID]}
                    showErrorIcon
                    inputAriaLabel={intl.formatMessage({ id: 'create-jobsite:primary-contact-placeholder' })}
                    required={true}
                />
                <PhoneNumber
                    name={JOBSITE_PHONE_NUMBER_ID}
                    label={intl.formatMessage({ id: 'create-jobsite:mobile-number' })}
                    value={selectedPhoneNumber}
                    handleInputChange={handlePhoneNumberChange}
                    dataTestId={checkoutDatalocator.phoneNumberPlaceHolder}
                    onValidate={setValidPhoneNumber}
                    placeholder={''}
                    required={true}
                    errorMessage={formErrors[JOBSITE_PHONE_NUMBER_ID]}
                    showErrorIcon
                    submit={submit}
                    setSubmit={setSubmit}
                    inputAriaLabel={intl.formatMessage({ id: 'create-jobsite:phone-number-placeholder' })}
                />
                <TextArea
                    name={JOBSITE_ACCESS_NOTES_ID}
                    value={accessNotes}
                    label={intl.formatMessage({ id: 'create-jobsite:access-notes-label' })}
                    handleChange={onChangeAccessNotes}
                    maxLength="160"
                    lbltestid={checkoutDatalocator.accessNotesHelperText}
                    dataTestId={checkoutDatalocator.accessNotesPlaceHolder}
                    errorMsg={formErrors[JOBSITE_ACCESS_NOTES_ID]}
                    showErrorIcon
                    textAriaLabel={intl.formatMessage({ id: 'create-jobsite:access-notes-placeholder' })}
                    fieldCustomClass="access-notes-text-box"
                />
                <small data-testid={checkoutDatalocator.letUsKnowIfThereAreText}>
                    {intl.formatMessage({ id: 'create-jobsite:instructions-let-us-know' })}
                </small>
                <Input
                    name={JOBSITE_PO_NUMBER_ID}
                    label={intl.formatMessage({ id: 'create-jobsite:po-number-label' })}
                    value={poNumber}
                    handleInputChange={onChangePONumber}
                    maxLength="26"
                    dataTestId={checkoutDatalocator.defaultPOnumberPlaceHolder}
                    errorMsg={formErrors[JOBSITE_PO_NUMBER_ID]}
                    showErrorIcon
                    inputAriaLabel={intl.formatMessage({ id: 'create-jobsite:po-number-placeholder' })}
                    fieldcustomclass="poNumberTextBox"
                />
                <small data-testid={checkoutDatalocator.ifYouWantToUseText} className="defaultPONumberText">
                    {intl.formatMessage({ id: 'create-jobsite:default-po-number-text' })}
                </small>
                {error !== '' && (
                    <Alert
                        className="alert"
                        type="error"
                        message={error}
                        localStyle="alert-withmargin"
                        icon={<AlertTriangleRed tabIndex={'-1'} aria-hidden={true} />}
                    />
                )}

                <Button
                    type="button"
                    onClick={submitForm}
                    className="button button-primary button-block createProjectButton"
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:create-jobsite-save-continue-cta' })}
                    data-testid={checkoutDatalocator.continueCTA}>
                    {intl.formatMessage({ id: 'checkout:create-jobsite-save-continue-cta' })}
                </Button>

                {systemicError && (
                    <AlertModalWithButtons
                        isOpen={systemicError}
                        title={intl.formatMessage({ id: 'checkout:systemic-error-title' })}
                        warningQuestion={renderSystemicErrorContent()}
                        handleCancelClick={toggleSystemicErrorModal}
                        cancelButtonText={intl.formatMessage({ id: 'checkout:systemic-error-cta-text' })}
                        handleCloseModal={toggleSystemicErrorModal}
                        customClass="systemic-error"
                    />
                )}

                {showReviewDuplicates && (
                    <ReviewDuplicates
                        newProject={newProject}
                        duplicates={duplicates}
                        isNewProject={isNewProject}
                        handleClose={toggleDuplicateView}
                        createProject={createNewProject}
                        onPressProject={onPressProject}
                        showReviewDuplicates={showReviewDuplicates}
                        setShowReviewDuplicates={setShowReviewDuplicates}
                        isCreateProjectLoading={isLoading}
                    />
                )}
            </>
        );
    };
    return (
        <EditableView
            ref={headingRef}
            children={renderEditableView()}
            currentStep={currentStep}
            currentTitle={intl.formatMessage({ id: 'checkout:create-jobsite-title' })}
            testId={checkoutDatalocator.checkout_create_jobsite_testid}
        />
    );
};

export default EditCreateJobsite;
