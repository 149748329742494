import React from 'react';
import { number, string } from 'prop-types';
import { useIntl } from 'react-intl';
import ContentCard from '../../../global/modules/contentCard/ContentCard';
import HelplineNumber from '../../../global/atoms/helplineNumber/HelplineNumber';
import '../accessoriesAndAddons.scss';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';

export default function AccessoryItemDesc(props) {
    const { title, imgSrc, quantity } = props;
    const intl = useIntl();
    let { phonenumber = '' } = document.querySelector('.checkout')?.dataset;
    const cardSideContent = () => {
        return (
            <div className="card-side-content">
                <span className="card-item-title">{title}</span>
                <small className="card-item-quanity">Qty: {quantity}</small>
            </div>
        );
    };
    const cardBottomContent = () => {
        return (
            <>
                {phonenumber ? (
                    <div className="helpline-container">
                        <HelplineNumber
                            message={intl.formatMessage({ id: 'checkout:accessories-and-addons-need-help-text' })}
                            textDataLocator={checkoutDatalocator.checkout_accessories_and_addons_needhelp_label}
                            phonenumberDataLocator={checkoutDatalocator.checkout_store_phoneNumber}
                            phoneNumber={phonenumber}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </>
        );
    };
    return (
        <ContentCard
            cardSideContent={cardSideContent}
            cardBottomContent={cardBottomContent}
            imageSource={imgSrc}
            customClass={'accessory-item-container'}
            customImgClass={'custom-img-container'}
            contentCardCustomClass={'content-container'}
            tabIndex={'-1'}
        />
    );
}

AccessoryItemDesc.propTypes = {
    title: string,
    imgSrc: string,
    quantity: number
};

AccessoryItemDesc.defaultProps = {
    title: '',
    imgSrc: '',
    quantity: 0
};
